import styled from 'styled-components';
import { Link } from "gatsby";
import React from 'react';
import { PageItemType } from '../molecules/MobileMenu';

type TopMenuItemType = {
    name: string,
    url: string,
    subPages?: PageItemType[];
}


export const TopMenuItemLink = styled(Link)`
    color: ${({ theme }) => theme.blueColor}; 
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    font-family: "Museo";
    margin: 10px 50px;
    transition: color 0.1s ease-in-out;
    display: block;

    :hover {
        color: ${({ theme }) => theme.blueHoverColor};
    }

    @media (min-width: 992px) and (max-width: 1600px) {
        margin: 10px 25px;
        font-size: 17px;
    }
`

const TopMenuItem: React.FC<TopMenuItemType> = ({ url, name }) => (
    <TopMenuItemLink to={url}>{name}</TopMenuItemLink>
)

export default TopMenuItem;