import styled from 'styled-components';
import React from 'react';
import exitButton from '../../images/button-exit.svg';

export type HamburgerProps = {
    isMobileMenuOpen: boolean;
    setIsMobileMenuOpen?: (isMobileMenuOpen: boolean) => void;
}

export const StyledExitButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    
    :focus {
        outline: none;
    }
`;

const StyledImage = styled.img`
    width: 35px;
    height: 20px;
`;


const ExitButton: React.FC<HamburgerProps> = ({ isMobileMenuOpen, setIsMobileMenuOpen}) => (
    <StyledExitButton onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
        <StyledImage src={exitButton} alt="Databout zamknięcie menu mobilnego"/>
    </StyledExitButton>
)


export default ExitButton;
