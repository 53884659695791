import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
    primaryColor: '#5A348B',
    blueColor: '#7B61FF',
    blueHoverColor: '#A391FF',
    blueLightColor: '#F1EEFF',
    secondaryColor: '#E83C51',
    tagColor: '#EEEBFD',
    whiteColor: '#fff',
    whiteHoverColor: '#DAD3FF',
    blackColor: '#292929',
    greyColor: '#9C9C9C',
    lightGreyColor: '#C4C4C4',
    yellowColor: '#F9B24F',
    backgroundColor: '#f5f5f5',
    popUpBackgroundColor: '#00000073',
    paddingDesktop: '0 132px',
    paddingLaptop: '0 100px',
    paddingTablet: '0 60px',
    paddingPhone: '0 16px',
    paddingDesktopDouble: '0 264px',
    paddingLaptopDouble: '0 160px',
    paddingTabletDouble: '0 120px',
    paddingPhoneDouble: '0 32px',
    columnTablet: '0 16px',
    columnLaptop: '0 20px',
    columnDesktop: '0 60px',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
    fontSize: {
       h1: '42px',
       h2: '36px',
       h3: '32px',
       h4: '24px',
       h5: '24px'
    }
}
