import React, { useEffect, useState } from "react";
import { theme } from '../theme/MainTheme';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyles from '../theme/GlobalStyles';
import TopMenu from "./organisms/TopMenu";
import Footer from "./organisms/Footer";
import MobileMenu from "./molecules/MobileMenu";
import Cookies from "./organisms/Cookies";

const LayoutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`

const ChildrenContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
`


const Layout = ({children}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [isStickyMenuVisible, setIsStickyMenuVisible] = useState<boolean>(false);
  
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 600) {
        setIsStickyMenuVisible(true);
      } else {
        setIsStickyMenuVisible(false);
      }
    });
  });

  return (
    <>
      <ThemeProvider theme={theme}>
      <GlobalStyles />
        <LayoutContainer>
          <TopMenu isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen}/>
          <TopMenu isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen} isSticky={isStickyMenuVisible ? "reveal" : "hidden"}/>
          <MobileMenu isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen}/>
          <ChildrenContainer>
            {children}
          </ChildrenContainer>
          <Footer />
        </LayoutContainer>
        <Cookies />
      </ThemeProvider>
    </>
  )
}


export default Layout;
