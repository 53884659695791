import styled from 'styled-components';
import { Link } from "gatsby";
import React from 'react';
import arrowRight from '../../images/arrow-right.svg';
import { PageItemType } from '../molecules/MobileMenu';

type MobileMenuItemType = {
    name: string,
    url: string,
    subPages?: PageItemType[];
}


export const MobileMenuItemLink = styled(Link)`
    color: ${({ theme }) => theme.whiteColor}; 
    text-decoration: none;
    font-size: 17px;
    font-weight: 300;
    line-height: 22px;
    font-family: "Museo Sans";
    padding: 16px 0;
    display: block;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.greyColor};
`

const StyledImage = styled.img`
    width: 8px;
    height: 20px;
`;

const MobileMenuItem: React.FC<MobileMenuItemType> = ({ url, name }) => (
    <MobileMenuItemLink to={url}>
        {name}
        <StyledImage src={arrowRight} alt="strzałka prawa"/>
    </MobileMenuItemLink>
)

export default MobileMenuItem;