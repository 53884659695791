/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";
import DataboutLogo from "../images/logo-primary.svg"
import { I18nextContext } from "gatsby-plugin-react-i18next";

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        author
      }
    }
  }
`

const SEO = ({ title, description, image = DataboutLogo, article, robotsMetaContent}) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);
  const defaultImage = DataboutLogo;
  const { language } = useContext(I18nextContext);

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    author
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image ? `${siteUrl}${image}` : defaultImage,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}
    htmlAttributes={{
      lang: language
    }}
>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={ seo.description && seo.description.length > 300 ? 
          seo.description.slice(0,300).split(' ').slice(0, -1).join(' ') + "..." : description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={ seo.description && seo.description.length > 300 ? 
          seo.description.slice(0,300).split(' ').slice(0, -1).join(' ') + "..." : description } />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      <meta name="author" content={author} />
      <link rel="sitemap" type="application/xml" title="Sitemap" href="/sitemap/sitemap-0.xml"></link>
      {robotsMetaContent && <meta name="robots" content={robotsMetaContent} />}
    </Helmet>
  )
}


SEO.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  image: null,
  article: false,
}

export default SEO
