import styled from 'styled-components';
import React from 'react';
import hamburgerMenu from '../../images/hamburger-menu.svg';

export type HamburgerProps = {
    isMobileMenuOpen: boolean;
    setIsMobileMenuOpen?: (isMobileMenuOpen: boolean) => void;
}

const StyledHamburgerButton = styled.button`
    display: none;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;

    :focus {
        outline: none;
    }

    @media (max-width: 1199.98px) {
        display: flex; 
    }
`;

const StyledImage = styled.img`
    width: 40px;
    height: 40px;
`;

const HamburgerButton: React.FC<HamburgerProps> = ({ isMobileMenuOpen, setIsMobileMenuOpen}) => (
    <StyledHamburgerButton onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
            <StyledImage src={hamburgerMenu} alt="Hamburger menu"/>
    </StyledHamburgerButton>
)


export default HamburgerButton;
