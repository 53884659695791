import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { TopMenuItemLink } from '../atoms/TopMenuItem';
import { PageItemType } from './MobileMenu';

type TopMenuDropdownType = {
    subPages: PageItemType[];
    name: string;
    url: string;
}

const StyledTopMenuDropdown = styled.div`
    display: flex;
    position: relative;

    ul {
        display: flex;
        flex-direction: column;
        display: none;
        height: 0px;
        position: absolute;
        left: 30px;
        padding: 21px;
        background-color: ${({ theme }) => theme.blueColor}; 
        top: 40px;
        border-radius: 8px;
        width: 260px;
        list-style-type: none;
    }
    :hover {
        ul {
            display: flex;
            height: auto;
        }
    }
`

const TopMenuDropdownItem = styled(Link)`
    width: 100%;
    font-family: 'Museo';
    font-weight: 600;
    font-size: 18px;
    line-height: 45px;
    text-decoration: none;
    color: ${({ theme }) => theme.whiteColor}; 
    display: block;
    :hover {
        color: ${({ theme }) => theme.whiteHoverColor};  
    }
`

const TopMenuDropdown: React.FC<TopMenuDropdownType> = ({ subPages, name, url }) => {
    return (
        <StyledTopMenuDropdown>
            <TopMenuItemLink to={url}>{name}</TopMenuItemLink>
            {subPages && <ul>
                {subPages.map(subPage => <li key={subPage.name}><TopMenuDropdownItem to={subPage.url}>{subPage.name}</TopMenuDropdownItem></li>)}
            </ul>}
        </StyledTopMenuDropdown>
    )
}

export default TopMenuDropdown;