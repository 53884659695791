import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import UnitedKingdomImg from '../../images/united-kingdom.svg';
import PolandImg from '../../images/poland.svg';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useTranslation } from "gatsby-plugin-react-i18next";

type LanguageType = "pl" | "en";

type LanguageObject = {
    name: LanguageType,
    flagUrl: string,
};

type LanguageDropdownProps = {
    isDropdownVisible: boolean;
};

 
const StyledLanguageSwitcher = styled.div`
    position: relative;
    margin-left: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (max-width: 1199.98px) {
        display: none; 
    }
`;

const Flag = styled.img`
    width: 23px;
    height: 23px;
    border-radius: 50%;
    box-shadow: ${({ theme }) => theme.boxShadow}; 

    @media (max-width: 1199.98px) {
        width: 34px;
        height: 34px;
    }
`;

const CurrentLanguage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const LanguageDropdown = styled.ul<LanguageDropdownProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40px;
    visibility: ${({isDropdownVisible}) => isDropdownVisible ? "visible" : "hidden"}; 
    background-color: ${({ theme }) => theme.whiteColor}; 
    border: 1px solid #C4C4C4;
    border-radius: 8px;
    list-style-type: none;
`;

const LanguageListItem = styled.li`
    display: flex;
    align-items: center;
    margin: 0;
    cursor: pointer;
    background-color: ${({ theme }) => theme.whiteColor}; 
    padding: 14px 16px; 
    border-radius: 8px;
    font-family: 'Museo Sans';
    font-weight: 300;
    font-size: 18px;
    line-height: 160%;
    width: 100%;

    img {
        box-shadow: none;
        margin-right: 10px;
    }
    :hover {
        background-color: ${({ theme }) => theme.whiteHoverColor}; 
    }
`;

const Arrow = styled.span`
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    position: relative;
    margin-top: 5px;
    margin-left: 10px;
`;

const LanguageSwitcherMobile = styled.div`
    display: none;
    align-items: center;

    @media (max-width: 1199.98px) {
        display: flex; 
    }
`;

const LanguageListItemMobile = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    cursor: pointer;
    span {
        margin-left: 10px;
        color: ${({ theme }) => theme.whiteColor};
    }
`;

const StyledSpan = styled.span`
    top: 0.5rem;
    position: absolute;
    width: .75rem;
    height: .1rem;
    background-color: ${({ theme }) => theme.blueColor};
    display: inline-block;
    transition: all .2s ease;

    &:first-of-type {
        left: 0;
        transform: rotate(45deg);
    }

    &:last-of-type {
        right: 0;
        transform: rotate(-45deg);
      }

    &.active:first-of-type {
        transform: rotate(-45deg);
    }

    &.active:last-of-type {
        transform: rotate(45deg);
    }
`;

const LanguageList: LanguageObject[] = [
    {
        name: "pl",
        flagUrl: PolandImg,
    },
    {
        name: "en",
        flagUrl: UnitedKingdomImg,
    }
]


 
const LanguageSwitcher: React.FC = () => {
    const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
    const {languages, changeLanguage, language} = useI18next();
	const {t} = useTranslation();
    
    return ( 
        <>
            <StyledLanguageSwitcher>
                <CurrentLanguage onClick={() => setIsDropdownVisible(!isDropdownVisible)}>
                    <Flag src={LanguageList.find(languageItem => languageItem.name === language).flagUrl} alt="flag"/>
                    <Arrow>
                        <StyledSpan className={isDropdownVisible && "active"}/>
                        <StyledSpan className={isDropdownVisible && "active"}/>
                    </Arrow>
                </CurrentLanguage>
                <LanguageDropdown isDropdownVisible={isDropdownVisible}>
                    {languages.filter(item => item !== language).map((lng) => (
                    <LanguageListItem key={lng} onClick={(e) => {changeLanguage(lng); setIsDropdownVisible(false);}}>
                        <Flag src={lng === "pl" ? PolandImg : UnitedKingdomImg} alt="flag"/>
                        <span>{t('language')}</span>
                    </LanguageListItem>
                    ))}
                </LanguageDropdown>
            </StyledLanguageSwitcher>
            <LanguageSwitcherMobile>
                {languages.filter(item => item !== language).map((lng) => (
                        <LanguageListItemMobile key={lng} onClick={(e) => {changeLanguage(lng); setIsDropdownVisible(false);}}>
                            <Flag src={lng === "pl" ? PolandImg : UnitedKingdomImg} alt="flag"/>
                            <span>{t('languageMobile')}</span>
                        </LanguageListItemMobile>
                    ))}
            </LanguageSwitcherMobile>
        </>
     );
}
 
export default LanguageSwitcher;