import React from 'react';
import styled, { css } from 'styled-components';
import ExitButton from '../atoms/ExitButton';
import MobileMenuItem from '../atoms/MobileMenuItem';
import { Link } from 'gatsby';
import logoMobile from '../../images/logo-mobile.svg';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'gatsby-plugin-react-i18next';

type MobileMenuProps = {
    isMobileMenuOpen: boolean;
    setIsMobileMenuOpen?: (isMobileMenuOpen: boolean) => void;
}

export type MainMenuItemType = {
    name: string,
    url: string,
    subPages?: PageItemType[]
}

export type PageItemType = {
    name: string,
    url: string
}

const StyledMobileMenu = styled.div<Pick<MobileMenuProps, "isMobileMenuOpen">>`
    position: fixed;
    background-color: ${({ theme }) => theme.primaryColor};
    width: 100%;
    max-width: 100vw;
    top: 0;
    left: 0;
    transform: translateX(100vw);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    display: none;
    padding: ${({ theme }) => theme.paddingTablet};
    padding-top: 35px;
    padding-bottom: 35px; 
    overflow-y: scroll;
    max-height: 100%;
    min-height: 100vh;

    ${({ isMobileMenuOpen }) =>
        isMobileMenuOpen &&
        css`
        transform: translateX(0vw);
        transition: transform 0.3s ease-in-out;
    `}

    @media (max-width: 1199.98px) {
        display: block; 
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        padding: ${({ theme }) => theme.paddingPhone};
        padding-top: 35px;
        padding-bottom: 35px; 
     }
`

const MobileMenuItems = styled.ul`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -50px;
    list-style-type: none;


    li {
        padding-bottom: 50px;
        width: calc(50% - 100px);
        margin: 0px 50px;
        a {
            font-family: Museo Sans;
        }
    }

    li a:first-child {
        font-family: Museo;
        font-weight: 600;
        border-bottom: 3px solid ${({ theme }) => theme.greyColor};
        font-size: 23px;
    }

    li a:last-child {
        border: none;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        margin: 0;
        li {
            width: 100%;
            margin: 0;
        }
     }
`

const MobileMenuNav = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 50px;
`

const StyledImage = styled.img`
    width: 34px;
    height: 34px;
`;

const MobileMenu: React.FC<MobileMenuProps> = ({ isMobileMenuOpen, setIsMobileMenuOpen}) => {
    const {t} = useTranslation();

    const MainMenuItems: MainMenuItemType[] = [
        //{
        //    name: t('tools.name'),
        //    url: "/tools/",
        //    subPages: [
        //        {
        //            name: t('tools.lis'),
        //            url: "/tools/lis/"
        //        }
                 //{
                 //    name: t('tools.source'),
                 //    url: "/tools/source/"
                 //}
                //{
                //    name: t('tools.harmony'),
                //    url: "/tools/harmony/"
                //}
        //    ]
        //},
        {
            name: t('services.name'),
            url: "/uslugi/",
            subPages: [
                {
                    name:  t('services.transport'),
                    url: "/uslugi/transport/"
                },
                {
                    name: t('services.powerEngineering'),
                    url: "/uslugi/energetyka/"
                },
                {
                    name: t('services.environment'),
                    url: "/uslugi/srodowisko/"
                },
                
            ]
        },
        {
            name: t('aboutUs'),
            url: "/o-nas/"
        },
        {
            name: t('career.title'),
            url: "/kariera/"
        },
        {
            name: t('contact.title'),
            url: "/kontakt/"
        },
    ]

    return (
        <StyledMobileMenu isMobileMenuOpen={isMobileMenuOpen}>
                <MobileMenuNav>
                    <Link to="/">
                        <StyledImage src={logoMobile} alt="Databout logo mobile"/>
                    </Link>
                    <ExitButton isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen}/>
                </MobileMenuNav>
                <LanguageSwitcher />
                <MobileMenuItems> 
                    {MainMenuItems.map(menuItem => 
                    <li key={menuItem.name}>
                        <MobileMenuItem url={menuItem.url} name={menuItem.name}/>
                        {menuItem.subPages && menuItem.subPages.map(subpage => <MobileMenuItem url={subpage.url} name={subpage.name} key={subpage.name}/>)}
                    </li>)}
                </MobileMenuItems>
        </StyledMobileMenu>
    )
}

export default MobileMenu;
