import React from 'react';
import styled, { css } from 'styled-components';
import HamburgerButton from '../atoms/HamburgerButton';
import { Link } from 'gatsby';
import TopMenuItems from '../molecules/TopMenuItems';
import logoPrimary from '../../images/logo-primary.svg';

export type TopMenuType = {
    setIsMobileMenuOpen?: (isMobileMenuOpen: boolean) => void;
    isMobileMenuOpen: boolean;
    isSticky?: "hidden" | "reveal";
}

const StyledTopMenu = styled.nav<Pick<TopMenuType, "isSticky">>`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(180deg, #F5F5F5 0%, rgba(196, 196, 196, 0) 100%);
    height: 75px;
    width: 100%;
    padding: ${({ theme }) => theme.paddingDesktop}; 
    transition: transform 0.3s ease;
    
    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: ${({ theme }) => theme.paddingTablet}; 
    }

    @media (min-width: 992px) and (max-width: 1600px) {
        padding: ${({ theme }) => theme.paddingLaptop}; 
    }

    @media (max-width: 767.98px) {
        padding: ${({ theme }) => theme.paddingPhone}; 
    }

    ${({ isSticky }) =>
        isSticky === "reveal" &&
        css`
            background: rgba(245, 245, 245, 0.65);
            backdrop-filter: blur(50px);
            position: fixed;
            transform: translateY(0px);
    `}

    ${({ isSticky }) =>
        isSticky === "hidden" &&
        css`
            background: rgba(245, 245, 245, 0.65);
            backdrop-filter: blur(50px);
            position: fixed;
            transform: translateY(-75px);
    `}
`

const StyledImage = styled.img`
    width: 194px;
    height: 34px;
`;

const TopMenu: React.FC<TopMenuType> = ({setIsMobileMenuOpen, isMobileMenuOpen, isSticky}) => {

    return (
        <StyledTopMenu isSticky={isSticky}> 
            <Link to="/">
                <StyledImage src={logoPrimary} alt="Databout logo" loading="eager"/>
            </Link>
            <HamburgerButton isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen}/>
            <TopMenuItems/>
        </StyledTopMenu>
    )
}

export default TopMenu;