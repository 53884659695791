import { createGlobalStyle } from "styled-components";
import "./fonts/MuseoSans.scss";
import "./fonts/Museo.scss";

const GlobalStyle = createGlobalStyle`
    *, *::before, *::after {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    body {
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-family: "Museo Sans", sans-serif;
        font-weight: 500;
        background-color: #f5f5f5;
    }

    li {
        font-family: 'Museo Sans';
        font-weight: 300;
        font-size: 18px;
        line-height: 160%;
        margin-left: 17px;
    }
    
    ul {
        padding: 0;
    }

    h1 {
        font-family: 'Museo';
        font-weight: 600;
        font-size: 46px;
        line-height: 86px;
    }

    h2 {
        font-family: 'Museo';
        font-weight: 600;
        font-size: 46px;
        line-height: 60px;
    }

    h3 {
        font-family: 'Museo';
        font-weight: 600;
        font-size: 36px;
        line-height: 36px;
    }

    p, span, td {
        font-family: 'Museo Sans';
        font-weight: 300;
        font-size: 18px;
        line-height: 160%;
    }


    h4 {
        font-family: 'Museo';
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
    }
    h5 {
        font-family: 'Museo Sans';
        font-weight: 300;
        font-size: 24px;
        line-height: 1.4;
    }

    figure {
        margin: 40px 0;
        width: 100%;

        img {
            width: 100%;
        }
    }

    .slick-slider {
        max-width: 100%;
    }

    .slick-track {
        display: flex;
        align-items: center;
    }

    .wp-block-image  {
        width: 100%;
    }

    .wp-block-file__button {
        display: none;
    }

    .wp-block-table {
        table {
        background-color: #aa99ff;
        margin: 0 auto;

        tbody {
            tr {    
                background-color: #ffffff;

                &:nth-of-type(odd) {
                background-color: #e4e0ff;
                }
                td {
                    padding: 10px 20px;
                    color: #000;
                    }
                }
            }
        }
    }

    a, button {
        -webkit-tap-highlight-color: transparent;
    }

    @media (max-width: 1600px) {
        h1 {
            font-size: 1.7em;
            line-height: 1.7em;
        }
        h2 {
            font-size: 40px;
            line-height: 48px;
        }
        h3 {
            font-size: 30px;
            line-height: 36px;      
        }
        p, span {
            font-size: 17px;
            line-height: 150%;
        }
        h4 {
            font-weight: 600;
            font-size: 23px;
            line-height: 32px;
        }

        h5 {
            font-size: 23px;
        }

    }
     @media (max-width: 1000px) {
        h1 {
            font-size: 1.4em;
            line-height: 1.4em;
        }
    }

`

export default GlobalStyle;
