import React from 'react';
import styled from 'styled-components';
import ButtonAnchor from '../atoms/ButtonAnchor';
import TopMenuItem from '../atoms/TopMenuItem';
import LanguageSwitcher from './LanguageSwitcher';
import TopMenuDropdown from './TopMenuDropdown';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { MainMenuItemType } from './MobileMenu';

const StyledTopMenuItems = styled.ul`
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;

    @media (max-width: 1199.98px) {
        display: none;
    }
`

// const ButtonSignIn = styled(ButtonAnchor)`
//    margin-left: 50px;
//    width: 188px;
//    border: 2px solid ${({ theme }) => theme.blueColor};

   
//    @media (min-width: 992px) and (max-width: 1600px) {
//         margin-left: 25px;
//     }
// `

const StyledLi = styled.li`
    margin-left: 0;
`;

const TopMenuItems: React.FC = () => {
    const {t} = useTranslation();

    const MainMenuItems: MainMenuItemType[] = [
        //{
        //    name: t('tools.name'),
        //    url: "/tools/",
        //    subPages: [
        //        {
        //            name: t('tools.lis'),
        //            url: "/tools/lis/"
        //        }
                //{
                //     name: t('tools.source'),
                //     url: "/tools/source/"
                // }
                //{
                //    name: t('tools.harmony'),
                //    url: "/tools/harmony/"
                //}
        //    ]
        //},
        {
            name: t('services.name'),
            url: "/uslugi/",
            subPages: [
                {
                    name:  t('services.transport'),
                    url: "/uslugi/transport/"
                },
                {
                    name: t('services.powerEngineering'),
                    url: "/uslugi/energetyka/"
                },
                {
                    name: t('services.environment'),
                    url: "/uslugi/srodowisko/"
                },
                
            ]
        },
        {
            name: t('aboutUs'),
            url: "/o-nas/"
        },
        {
            name: t('career.title'),
            url: "/kariera/"
        },
        {
            name: t('contact.title'),
            url: "/kontakt/"
        },
    ]

    return (
        <StyledTopMenuItems>
            {MainMenuItems.map((menuItem, index) => <StyledLi key={index}>
                {menuItem.subPages ? <TopMenuDropdown url={menuItem.url} name={menuItem.name} subPages={menuItem.subPages}/> :
                    <TopMenuItem url={menuItem.url} name={menuItem.name} />}
            </StyledLi>)}
            <StyledLi>
                <LanguageSwitcher />
            </StyledLi>
        </StyledTopMenuItems>
    )
}

export default TopMenuItems;
