import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import IconCookies from '../../images/icon-cookies.svg';
import { useTranslation } from "gatsby-plugin-react-i18next";
import Cookies from 'universal-cookie';

const StyledCookies = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(19, 19, 19, 0.914);
    color: #000;
    z-index: 1000000000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${({ theme }) => theme.paddingDesktop};
    padding-top: 20px;
    padding-bottom: 20px;

    @media (max-width: 767.98px) {
        padding: ${({ theme }) => theme.paddingPhone}; 
        padding-top: 16px;
        padding-bottom: 16px;
        flex-direction: column;
    } 
    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: ${({ theme }) => theme.paddingTablet}; 
        padding-top: 20px;
        padding-bottom: 20px;
    }
    @media (min-width: 992px) and (max-width: 1600px) {
        padding: ${({ theme }) => theme.paddingLaptop}; 
        padding-top: 20px;
        padding-bottom: 20px;
    }
`

const CookiesTextWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const CookiesImg = styled.img`
    max-width: 40px;
    height: 40px;

    @media (max-width: 767.98px) {
        display: none;
    } 
    
    @media (min-width: 768px) and (max-width: 991.98px) {
        max-width: 30px;
    }
`

const CookiesText = styled.span`
    color: ${({ theme }) => theme.whiteColor};
    font-size: 14px;
    padding: 0px 20px;
    margin: 0;

    @media (max-width: 767.98px) {
        padding: 0px 15px 0px 0px;
        text-align: center;
    } 
`

const PrivacyPolicyLink = styled.a`
    color: ${({ theme }) => theme.secondaryColor};
    text-decoration: none;
    white-space: nowrap;

    :hover {
        opacity: 0.8;
    }
`

const CookiesButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1400px) {
        flex-wrap: wrap;
    } 
`

const CookiesButton = styled.button`
    color: ${({ theme }) => theme.secondaryColor};
    border: 2px solid ${({ theme }) => theme.secondaryColor};
    background-color: transparent;
    padding: 8px 12px;
    font-size: 14px;
    white-space: nowrap;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out;
    border-radius: 4px;
    cursor: pointer;
    font-family: "Museo", sans-serif;
    font-weight: 700;
    margin: 8px;

    :hover {
        background-color: rgba(236, 68, 90, 0.164);
    }

    :focus {
        outline: none;
    }

    @media (max-width: 767.98px) {
        font-size: 12px;
        padding: 10px 12px;
        margin-top: 16px;
    } 
`
const cookiesAcceptedKey = 'gatsby-gdpr-google-analytics';

const cookies = new Cookies();
function areCookiesAccepted() {
    return (cookies.get("cookiesAccepted") ? true : false); 
}

type cookie = "functional" | "all";

const CookiesModal = () => {
	const {t} = useTranslation();
    const [showCookies, setShowCookies] = useState(false);

    const cookiesAccept = (cookie: cookie) => {
        const time = Date.now();
        const expireTime = time + 356 * 86400 * 1000;

        if (cookie === "all") {
            cookies.set(cookiesAcceptedKey, true.toString(), { path: '/', expires: new Date(expireTime)} );
        }

        cookies.set("cookiesAccepted", cookie);
        setShowCookies(false);
    }   

    useEffect(()=>{
        setShowCookies(!areCookiesAccepted())
    },[])

    if (showCookies) {
        return (
            <StyledCookies>
                <CookiesTextWrapper>
                    <CookiesImg src={IconCookies} alt="cookies"/>
                    <CookiesText>
                        {t('cookiesText')}
                        <PrivacyPolicyLink href="/polityka-prywatnosci.pdf" target="_blank">&nbsp;{t('cookiesLink')}</PrivacyPolicyLink>
                    </CookiesText>
                </CookiesTextWrapper>
                <CookiesButtonsWrapper>
                    <CookiesButton onClick={() => cookiesAccept("functional")}>{t('cookiesButton1')}</CookiesButton>
                    <CookiesButton onClick={() => cookiesAccept("all")}>{t('cookiesButton2')}</CookiesButton>
                </CookiesButtonsWrapper>
            </StyledCookies>
        )
    }

    return <></>
}

export default CookiesModal;
